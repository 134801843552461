// WordChangeOverlay.js

import React, { useState } from 'react';
import { FaWrench, FaCog, FaExpand, FaDownload, FaChevronRight, FaSave } from 'react-icons/fa';
import { FaGavel } from 'react-icons/fa6';
import { TbSticker, TbPhoto, Tb } from 'react-icons/tb';

import './WordChangeOverlay.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';

import { handleFullScreen, handlePetAvatar, handleCreateSticker, handleSaveAccessory, handleAuction, handlePermanentSave } from './menuHandlers';


function WordChangeOverlay({
  gameContext,
}) {

  const { t } = useTranslation();

  const {
    drawMethodsRef,
    gameInfoRef,
    saveGameSets, 
    saveUserSets, 

    changeWord,
    activateHints,
    openLetter,

    openMenu, closeMenu,
    showPopupMessage,
    showTemporaryHint,
    delayPremiumMenu,

    canDraw,
    userSetsRef,
    groupSetsRef,
    premiumFeaturesRef,
    gameWordFun,
    moderatorModeRef,

    hostAction,
    isAppleDevice,
  } = gameContext;

  const drawMethods = drawMethodsRef.current;
  const { render } = drawMethods;

  const game = gameInfoRef.current;

  const hintsEnabled = game.hint?.active;
  const hints = game.hint?.hints || [];

  const knowTheWord = canDraw && !moderatorModeRef.current;
  const showTheWord = knowTheWord || game.settings.accessory;

  const [changingWord, setChangingWord] = useState(false);
  const [wordInputValue, setWordInputValue] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);


  const userSettings = [
    { key: 'fullscreen', label: t('settings.fullscreen') },
  ];
  const gameSettings = [
    { key: 'plus18', label: t('settings.plus18') },
  ];

  const handleGameSetCheckbox = (key) => {
    const newValue = !game.settings[key];
    saveGameSets({ [key]: newValue })
  };

  const handleUserSetCheckbox = (key) => {
    const newValue = !userSetsRef.current[key];
    saveUserSets({ [key]: newValue })

    if (key === 'fullscreen') {
      if (newValue) {
        try { window.Telegram.WebApp.requestFullscreen(); } catch (e) {}
      } else {
        try { window.Telegram.WebApp.exitFullscreen(); } catch (e) {}
      }
    }
  };


  const handleInputFocus = (e) => {
    
    if (game?.settings?.accessory) { return setIsInputFocused(true); }

    if (
      !premiumFeaturesRef.current.includes('personoalWord')
      && !gameInfoRef.current.board 
      ) {
      e.target.blur(); setIsInputFocused(false); setWordInputValue('');
      // closeMenu();
      return delayPremiumMenu('menu');
    }
    
    if (
      !groupSetsRef.current.customWord 
      && !gameInfoRef.current.board 
      // && !groupSetsRef.current.notGroup 
      && !gameInfoRef.current.results
      ) {
      e.target.blur(); setIsInputFocused(false); setWordInputValue('');
      const popupParams = {
        title: t('word.custom_is_off_title'),
        message: t('word.custom_is_off_text'),
      };
      return showPopupMessage(popupParams)
    }

    setIsInputFocused(true);

  };

  const handleInputSubmit = () => {
    const customWord = wordInputValue.trim();
    if (!customWord) {return setWordInputValue('');}

    if (game?.board || game?.settings.accessory) {
      saveGameSets({ title: wordInputValue }, ()=>{
        setWordInputValue('');
      });
    } else {
      changeWord(wordInputValue).then(word=>{
        setWordInputValue('');
      });
    }

  };

  const activateHintsFun = ()=>{

    if (!canDraw) { return }

    const hintsNotAvailable = false;
    // const hintsNotAvailable = !premiumFeaturesRef.current.includes('hints');

    if (hintsNotAvailable) {
      closeMenu();
      delayPremiumMenu('menu');
    } else {
      activateHints();
    }

  }


  const definition = game.definitions?.[game.word] || ' ';
  const [showFullDefinition, setShowFullDefinition] = useState(false);
  const toggleDefinition = () => {
    setShowFullDefinition(!showFullDefinition);
  }

  const renderDefinition = () => {
    if (!definition) return null;
    
    if (definition.length > 140 && !showFullDefinition) {
      return (
        <div className="definition-display">
          {definition.substr(0, 120).replace(/\s+\S*$/, "")}
          <span>... </span>
          <button className="inline-more-button" onClick={toggleDefinition}>{t('word.button_definition_more')}</button>
        </div>
      );
    } else if (definition.length > 400) {
      return (
        <div className="definition-display">
          {definition.substr(0, 400).replace(/\s+\S*$/, "")}
          <span>... </span>
        </div>
      );
    } else {
      return (
        <div className="definition-display">
          {definition}
        </div>
      );
    }
  };


  function wordAndHints () {

    return(
      <>
        {changingWord ? (
          <>
              {wordInputValue ? 
                (
                  <div className="button-row">
                   <button className="wide-button" 
                    onClick={handleInputSubmit}
                    // onMouseDown={(e) => e.preventDefault()} 
                    // onTouchStart={(e) => e.preventDefault()} 
                   >
                    <FaChevronRight style={{ marginRight: '5px', marginBottom: '3px', verticalAlign: 'middle'}} />
                    {wordInputValue}
                   </button>
                 </div>
                ) : !game.board ?
                (
                  <div className="button-row">
                    <button className="small-button" onClick={() => changeWord('previous')}>
                      {t('word.button_previous')}
                    </button>
                    <button className="small-button" onClick={() => changeWord('next')}>
                      {t('word.button_new')}
                    </button>
                 </div>

                ) : null
              }

            { (
              <div className="button-row">
                <input
                  className="word-input-field input-field"
                  type="text"
                  value={wordInputValue}
                  onChange={(e) => setWordInputValue(e.target.value)}
                  onFocus={ handleInputFocus }
                  onBlur={() => setIsInputFocused(false)}
                  placeholder={t('word.placeholder')}
                />
              </div>
            )}
            
            
          </>
        ) : (
          <button className="wide-button" onClick={()=>{
            setChangingWord(true)
            }}>
            {t('word.button_change_word')}
          </button>
        )}

        {
          (game?.board || game?.settings.accessory) ? null :
          !hintsEnabled ? (
            <button className="wide-button" onClick={activateHintsFun}>
              {t('word.button_hints')}
            </button>
          ) : (
            <div className="hints-container">
              {hints.map((hint, num) => (
                <button 
                  key={num} 
                  className={hint.opened ? "hint-active-button" : "hint-button"}
                  onClick={()=>{openLetter(num)}}
                  >
                  {hint.letter}
                </button>
              ))}
            </div>
          )
        }
      </>
    )
    
  }


  return (
    <>
      <div className="overlay-background" onClick={()=>{
        setShowFullDefinition(false);
        closeMenu(); 
        }}></div>
      <div className="word-menu">
        <div className="word-display">{gameWordFun()}</div>

        {showTheWord && renderDefinition()}
        {knowTheWord && game.mode !== 'sprint' && (
          wordAndHints()
        )}

        <div className='word-menu-settings'>

          {gameSettings.map((setting) => (
            <div key={setting.key} className="word-menu-item">
              <label className="settings-label" style = {{
                opacity: game?.settings[setting.key] ? 1 : 0.5
              }}>
                <input
                  type="checkbox"
                  className='word-menu-item-checkbox'
                  checked={game?.settings[setting.key]}
                  onChange={() => handleGameSetCheckbox(setting.key)}
                  style={{ height: '20px', }}
                />
                <span>{setting.label}</span>
              </label>
            </div>
          ))}
          
          {userSettings.map((setting) => (
            <div key={setting.key} className="word-menu-item">
              <label className="settings-label" style = {{
                opacity: userSetsRef.current[setting.key] ? 1 : 0.5
              }}>
                <input
                  type="checkbox"
                  className='word-menu-item-checkbox'
                  checked={userSetsRef.current[setting.key]}
                  onChange={() => handleUserSetCheckbox(setting.key)}
                  style={{ height: '20px', }}
                />
                <span>{setting.label}</span>
              </label>
            </div>
          ))}


          <div className="word-menu-item" onClick={()=>{handleFullScreen({ t, hostAction, showPopupMessage, showTemporaryHint })}}>
            <label className="settings-label">
            <FaExpand className="settings-label-icon"/>
              <span>{t('fullscreen.button_start')}</span>
            </label>
          </div>

          <div className="word-menu-item" onClick={()=>{handlePermanentSave({ t, hostAction, showPopupMessage, showTemporaryHint })}}>
            <label className="settings-label">
            <FaSave className="settings-label-icon"/>
              <span>{t('permanent.button_permanent')}</span>
            </label>
          </div>

          { gameInfoRef.current.accessory ? 
            (
              <div className="drawing-menu-item" onClick={()=>{handleSaveAccessory({ t, hostAction, showPopupMessage })}}>
                <label className="settings-label">
                <FaDownload className="settings-label-icon"/>
                  <span>{t('pet.accessory.button_save')}</span>
                </label>
              </div>
            ) : (
            <>
            { !game.clone && (
                <div className="word-menu-item" onClick={()=>{handlePetAvatar({ t, hostAction, showPopupMessage })}}>
                  <label className="settings-label">
                  <TbPhoto className="settings-label-icon"/>
                    <span>{t('pet.avatar.button_set')}</span>
                  </label>
                </div>
            )}
             <div className="drawing-menu-item" onClick={()=>{handleCreateSticker({ t, hostAction, showPopupMessage })}}>
              <label className="settings-label">
              <TbSticker className="settings-label-icon"/>
                <span>{t('sticker.button_create')}</span>
              </label>
            </div>
          </>
          )}

          { !gameInfoRef.current.accessory && !game.clone && (
            <div className="drawing-menu-item" onClick={()=>{handleAuction({ t, hostAction, showPopupMessage, game })}}>
            <label className="settings-label">
            <FaGavel className="settings-label-icon"/>
              <span>{t('auction.button_apply')}</span>
            </label>
            </div>
          )}

        </div>
     

        <button className="gray-button" onClick={()=>{openMenu('drawing', true)}}>
          <FaWrench style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}} />
          {t('drawing.title')}
        </button>

        <button className="gray-button" onClick={()=>{openMenu('settings', true)}}>
          <FaCog style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}} />
          {t('settings.title')}
        </button>

      </div>
    </>
  );
}

export default WordChangeOverlay;
